<!-- eslint-disable no-tabs -->
<template>
    <div class="impresum-modal">
      <div class="modal-overlay" @click="close"></div>
      <div class="modal-container">
        <div class="modal-header">
          <h3>IMPRESUM</h3>
          <button class="btn btn-primary" @click="close">Zatvori</button>
        </div>
        <div class="modal-body">
          <p><b>Obrt "PLANTEC" vl. Jasmina Đelmo</b></p>
					<p><b>Sjedište:</b> Bosanska 1/8, 88420 Jablanica, Bosna i Hercegovina</p>
					<p><b>Telefon</b>: +38760 340 0186</p>
					<p><b>Email:</b> <a href="mailto:info@plantec.ba">info@plantec.ba</a></p>
					<br>
					<p>Opstina Jablanica, Hercegovačko-Neretvanski kanton</p>
					<p><b>JIB:</b> 4328964560005</p>
					<p><b>Direktor:</b> Jasmina Đelmo</p>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'ImpresumModal',
  computed: {
  },
  methods: {
    close() {
      this.$store.dispatch('setImpresumModal', false);
    },
  },
};
</script>

  <style>
 .impresum-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.modal-container {
  background: #fff;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.modal-close {
  border: none;
  background: transparent;
  font-size: 30px;
  cursor: pointer;
}

.modal-body {
  margin-top: 20px;
}

.modal-description {
  font-size: 16px;
  line-height: 1.5;
}
</style>
