<template>
  <div id="page" class="page">
    <MainComponent />
  </div>
</template>

<script>
import MainComponent from '@/components/pages/MainComponent';

export default {
  components: {
    MainComponent,
  },
  computed: {
  },
  watch: {
    $route(to) {
      document.title = to.meta.title || 'Plantec';
    },
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&family=Teko:wght@400;500;600&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.0/css/all.min.css');
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css');
@import '../../assets/css/style.css';
@import '../../assets/css/bootstrap.min.css';

#app {
margin: 0px;
padding: 0px;
overflow: hidden;
}
</style>
