<template>
  <div ref="main">
    <Header />
    <router-view />
    <!-- Back to Top -->
    <a @click.stop="scrollToTop"
      class="btn btn-lg btn-primary btn-lg-square back-to-top"><i class="bi bi-arrow-up"></i></a>
    <ImpresumModal v-if="modalOpen" />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/elements/Header';
import Footer from '@/components/elements/Footer';
import VueScrollTo from 'vue-scrollto';
import ImpresumModal from '@/components/widgets/ImpresumModal';

export default {
  name: 'MainComponent',
  components: {
    Header,
    Footer,
    ImpresumModal,
  },

  data() {
    return {
      showNavbar: true,
      lastScrollPosition: 0,
    };
  },
  created() {
    this.onPageLoad();
  },
  destroyed() {
    // window.removeEventListener('scroll', this.onScroll);
  },
  computed: {
    modalOpen() {
      return this.$store.getters.impresumModal;
    },
  },
  methods: {
    scrollToTop() {
      VueScrollTo.scrollTo('#top', 500);
    },
  },
};
</script>
