import { RootState as IState } from '@/types';

const getDefaultState = (): IState => ({
  routes: {
    local: {
      root: 'https://api.anyauto.aksa.dev/',
    },
  },
  impresumModal: false,
});

export default getDefaultState;
