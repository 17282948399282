/**
 * Main vuex mutations
 * @packageDocumentation
 * @category Store
 */
import Vue from 'vue';
import { MutationTree } from 'vuex';
import { RootState as State } from '@/types';
import getDefaultState from './state';

const mutations: MutationTree<State> = {
  setImpresumModal(state, payload: boolean) {
    state.impresumModal = payload;
  },
};

export default mutations;
