import Vue from 'vue';
import Router from 'vue-router';
import store from './store';
import getters from './store/getters';

Vue.use(Router);

const router = new Router({
  // mode: 'hash',
  mode: 'history',
  linkActiveClass: 'menu-active',
  linkExactActiveClass: 'menu-active',
  routes: [
    {
      path: '*',
      name: 'notfound',
      component: () => import(/* webpackChunkName: "home" */'./components/pages/NotFound.vue'),
    },
    // -
    {
      path: '/',
      name: '',
      component: () => import(/* webpackChunkName: "home" */'./components/pages/Home.vue'),
      meta: { guest: true },
    },
    // Home page
    {
      path: '/home',
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */'./components/pages/Home.vue'),
      meta: { guest: true },
    },
  ],
});

/* router.beforeEach((to, from, next) => {
  next();
}); */

export default router;
