<!-- eslint-disable no-tabs -->
<template>
	<div>
		<!-- Topbar Start -->
		<div id="top" class="container-fluid bg-dark p-0 wow fadeIn" data-wow-delay="0.1s">
				<div class="row gx-0 d-none d-lg-flex">
						<div class="col-lg-7 px-5 text-start">
							<div class="h-100 d-inline-flex align-items-center py-3 me-3">
								<a class="text-body px-2" href="tel:+387603400186">
									<i class="fa fa-phone-alt text-primary me-2"></i>+38760 340 0186</a>
								<a class="text-body px-2" href="mailto:info@example.com">
									<i class="fa fa-envelope-open text-primary me-2">
									</i>info@plantec.ba</a>
							</div>
						</div>
						<div class="col-lg-5 px-5 text-end">
								<div class="h-100 d-inline-flex align-items-center py-3 me-2 cursor-pointer">
										<a class="text-body px-2" @click.stop="setImpresumModal">Impresum</a>
								</div>
								<div
                  class="h-100 d-inline-flex align-items-center cursor-pointer"
                  @click="setLanguage(isCurrentLanguageBs ? 'en' : 'bs')">
                  <i class="px-1 fas fa-globe"></i>
                  <p class="mt-3">{{ isCurrentLanguageBs ? 'EN' : 'BS' }}</p>
								</div>
						</div>
				</div>
		</div>
	<!-- Topbar End -->

    <!-- Navbar Start -->
    <nav
			class="navbar navbar-expand-lg bg-white navbar-light sticky-top py-lg-0 px-lg-5 wow fadeIn"
				data-wow-delay="0.1s">
        <a @click.stop="scrollToTop" class="navbar-brand ms-4 ms-lg-0">
            <h1 class="text-primary m-0">
						<img class="me-3" src="../../assets/img/logo.png" alt="Icon">
            Plantec
          </h1>
        </a>
        <button type="button" class="navbar-toggler me-4"
          data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
          @click="openNavbar">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse"
          :class="{ 'show': isMobile && openNav }">
            <div class="navbar-nav ms-auto p-4 p-lg-0">
              <a @click.stop="scrollToTop" class="nav-item nav-link active cursor-pointer">
                {{ translate('home') }}</a>
              <a @click.stop="scrollToContact" class="nav-item nav-link cursor-pointer">
                {{ translate('contact') }}</a>
              <div
                v-if="isMobile && openNav"
                class="h-100 d-inline-flex align-items-center py-3 me-2 cursor-pointer">
                  <a class="text-body" @click.stop="setImpresumModal">Impresum</a>
              </div>
              <div
                v-if="isMobile && openNav"
                class="h-100 d-inline-flex align-items-center cursor-pointer"
                @click="setLanguage(isCurrentLanguageBs ? 'en' : 'bs')">
                <i class="px-1 fas fa-globe"></i>
                <p class="mt-3">{{ isCurrentLanguageBs ? 'EN' : 'BS' }}</p>
              </div>
            </div>
        </div>
    </nav>
    <!-- Navbar End -->
   </div>
</template>
<script>
// import LanguageDropdown from '@/components/widgets/LanguageDropdown';
// import FixedHeader from 'vue-fixed-header';
import VueScrollTo from 'vue-scrollto';

export default {
  name: 'Header',
  components: {
    // LanguageDropdown,
    // FixedHeader,
  },
  data() {
    return {
      showLangDropdown: false,
      showMobileManu: false,
      openNav: false,
    };
  },
  computed: {
    /*
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    currentUser() {
      return this.$store.getters.getCurrentUser;
    },
    showMobileMenu() {
      return this.$store.getters.showMobileMenu;
    }, */
    isMobile() {
      const userAgent = navigator.userAgent.toLowerCase();
      const mobilePattern = /mobile|android|ios|iphone|ipad|ipod|windows phone/i;
      return mobilePattern.test(userAgent);
    },
  },
  methods: {
    scrollToTop() {
      VueScrollTo.scrollTo(0, 500);
    },
    scrollToAbout() {
      VueScrollTo.scrollTo('#about', 500);
    },
    scrollToServices() {
      VueScrollTo.scrollTo('#services', 500);
    },
    scrollToContact() {
      VueScrollTo.scrollTo('#contact', 500);
    },
    openNavbar() {
      this.openNav = !this.openNav;
    },
    /* logOut() {
      this.$store.dispatch('logout');
      this.$router.push('/login');
    },
    setMobileManu(value) {
      this.$store.dispatch('setShowMobileMenu', value);
    }, */
    setLanguage(key) {
      if (key === this.currentLanguage) return;
      this.changeLanguage(key);
    },
    setImpresumModal() {
      this.$store.dispatch('setImpresumModal', true);
    },
  },
};
</script>
<style>
.navbar>.container {
   display: block;
}

.isCurrentLanguage {
   background-color: #FFFFFF;
   border-radius: 50%;
}
</style>
