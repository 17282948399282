import Vue from 'vue';
// @ts-ignore
import App from '@/components/pages/App';
import { translate } from '@/utilities/filters';
import { languages } from '@/mixins/';
import removeLoader from '@/mixins/removeLoader';
import Toast from 'vue-toastification';
import router from './router';
import store from './store';
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';

Vue.mixin(languages);
Vue.mixin(removeLoader);
Vue.filter('translate', translate);
Vue.config.productionTip = false;

Vue.use(Toast);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
