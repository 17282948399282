import { LanguagesState as IState } from '@/types';

const State: IState = {
  currentLanguage: 'bs',
  translations: {
    en: {
      // header
      home: 'Home',
      contact: 'Contact',
      bannerTitle: '3D modeling and plans for architectural and engineering objects',
      bannerDescription: 'We offer modeling and plan services for a wide range of construction activities. With over 10 years of experience in the design of concrete, steel and wooden structures, we are a reliable partner for the preparation of your next project.',
      contactUs: 'Contact us',
      service1: '3D models and plans of objects',
      service2: '3D models and plans of facades and armatures',
      service3: '3D models and plans of steel and wooden structures',
      contactSectionTitle: 'Do you have an idea for a project? Contact us and we will arrange a meeting!',
      contactSectionDescription: 'No matter the size of your project, we are ready for cooperation! Contact us and we will make a plan for realization!',
      callUs: 'Call us',
      sendEmail: 'Send email to',
      yourFirstName: 'Your first name',
      yourLastName: 'Your last name',
      yourEmail: 'Your email',
      yourMessage: 'Your message',
      yourPhone: 'Your phone number',
      sendMessage: 'Send message',
      address: 'Address',
      successMessage: 'Success! Your message has been received and we will be in touch shortly.',
      errorMessage: 'Error! Something went wrong. Please try again later.',
    },
    bs: {
      // header
      home: 'Početna',
      contact: 'Kontakt',
      bannerTitle: 'Izrada 3D modela i planova za arhitektonske i inžinjerske objekte',
      bannerDescription: 'Nudimo usluge modeliranja i izrade planova za širok spektar građevinskih djelatnosti. Sa preko 10 godina iskustva u projektovanju betonskih, čeličnih i drvenih konstrukcija predstavljamo pouzdanog partnera za izradu vašeg sljedećeg projekta.',
      contactUs: 'Kontaktirajte nas',
      service1: '3D modeli i planovi objekata',
      service2: '3D modeli i planovi oplate i armature',
      service3: '3D modeli i planovi čeličnih i drvenih kontrukcija',
      contactSectionTitle: 'Imate ideju za projekt? Javite nam se i dogovorimo sastanak!',
      contactSectionDescription: 'Bez obzira na veličinu vašeg projekta, mi smo spremni za suradnju! Javite nam se i napravimo plan za realizaciju!.',
      callUs: 'Pozovite nas',
      sendEmail: 'Pošaljite email na',
      yourFirstName: 'Vaše ime',
      yourLastName: 'Vaše prezime',
      yourEmail: 'Vaš email',
      yourMessage: 'Vaša poruka',
      yourPhone: 'Vaš broj telefona',
      sendMessage: 'Pošalji poruku',
      address: 'Adresa',
      successMessage: 'Uspješno! Vaša poruka je primljena i uskoro ćemo se javiti.',
      errorMessage: 'Greška! Nešto je pošlo po zlu. Molimo pokušajte ponovo kasnije.',
    },
  },
};

export default State;
