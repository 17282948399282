/**
 * Main vuex actions
 * @packageDocumentation
 * @category Store
 */
import { ActionTree } from 'vuex';
import { RootState as State } from '@/types';

const BASE_URL = 'http://localhost:5000/api/';

const actions: ActionTree<State, any> = {
  setImpresumModal({ commit }, payload) {
    commit('setImpresumModal', payload);
  },
};

export default actions;
